<app-page [content]="content" />

<ng-template #content>
  <div class="home-page-layout">
    <h1 class="text-bold m-t-5 m-b-3">Dashboard</h1>

    <p
      *ngIf="user$ | async as user"
      class="m-b-5"
    >
      Welcome to the Admin Centre for {{ (organisationName$ | async) }}, you are logged in as {{
        (userName$ | async)
      }}.
    </p>

    <div class="content-layout-column mt-4 mb-4">
      <app-content
      [key]="this.organisationSummaryKey"
      [value]="this.organisationSummaryValue$ | async"
      [trackingId]="this.organisationSummaryKey"
      />
    </div>

    <campaign-results
      [campaignSummary]="this.campaignSummary$ | async"
      [canViewEngagements]="this.engagementPermission$ | async"
    ></campaign-results>

    <div *ngIf="this.biReportsPermission$ | async">
      <h2 class="m-b-3 text-medium">Reports</h2>
      <p>
        <a routerLink="/reports">Organisation reports</a>
      </p>
    </div>
  </div>

</ng-template>
<support-widget />

