import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AuthService, User } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import { catchError, combineLatest, map, Observable, of, switchMap } from 'rxjs';
import { ContentComponent } from '../../common/content/content.component';
import { PageComponent } from '../../common/page/page.component';
import { CampaignResultsComponent } from '../../components/campaign-results/campaign-results.component';
import { SupportWidgetComponent } from '../../components/support-widget/support-widget.component';
import { Permissions } from '../../constants/auth-permissions';
import { JwtPayloadParserService } from '../../services/jwt-payload-parser.service';
import { setAdminContents } from '../../store/actions/content.actions';
import { AdminContentState } from '../../store/models/content-models';
import { selectAdminContentByKey } from '../../store/selectors/content.selectors';
import {CampaignDashboardsResponse} from '../../models/response-models';
import {AdminPortalApiService} from '../../services/admin-portal-api.service';
import {campaignDataActions} from '../../store/actions/campaign.actions';
import {selectCampaignData, selectCampaignState} from '../../store/selectors/campaign.selectors';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrl: 'home.component.scss',
  standalone: true,
  imports: [
    PageComponent,
    AsyncPipe,
    CampaignResultsComponent,
    NgIf,
    RouterLink,
    ContentComponent,
    SupportWidgetComponent,
  ],
})
export class HomePageComponent implements OnInit {
  protected user$: Observable<User | null | undefined>;
  protected biReportsPermission$: Observable<boolean>;
  protected organisationSummaryKey: string = 'organisation-summary';
  protected organisationSummaryValue$ = this.contentStore.select(selectAdminContentByKey(this.organisationSummaryKey));
  protected userName$: Observable<string | undefined>;
  protected organisationName$: Observable<string>;
  protected campaignSummary$: Observable<CampaignDashboardsResponse | null> = this.store.select(selectCampaignData);
  protected engagementPermission$: Observable<boolean>;


  constructor(
    private readonly authService: AuthService,
    private readonly jwtParser: JwtPayloadParserService,
    private readonly contentStore: Store<AdminContentState>,
    private store: Store
  ) {
    this.user$ = this.authService.user$;
  }

  ngOnInit(): void {
    this.biReportsPermission$ = this.authService
      .getAccessTokenSilently()
      .pipe(map((token) => (token && this.jwtParser.hasPermission(token, Permissions.readBIReports)) || false));

    this.contentStore.dispatch(setAdminContents());

    this.userName$ = this.user$.pipe(map((user) => user?.name ?? user?.email));

    let organisationDisplayName = this.authService.getAccessTokenSilently().pipe(
      map((token) => this.jwtParser.getCustomClaim(token, 'organisation.name')));

    this.organisationName$ = organisationDisplayName.pipe(
      switchMap(displayName => {
        if (!displayName) {
          return this.authService.user$.pipe(map((user) => user?.['org_name'] as string));
        }

        return of(displayName);
      })
    )

    this.store.dispatch(campaignDataActions.getCampaignData())
    this.engagementPermission$ = this.authService.getAccessTokenSilently().pipe(
      map((token) => token && this.jwtParser.hasPermission(token, Permissions.readEngagements) || false));
  }
}
