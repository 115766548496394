import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, isDevMode, provideZoneChangeDetection } from '@angular/core';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter } from '@angular/router';
import { authHttpInterceptorFn, provideAuth0 } from '@auth0/auth0-angular';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import { environment } from '../environments/environment';
import { routes } from './app.routes';
import { AssessmentsEffects } from './store/effects/assessments.effects';
import { ContentEffects } from './store/effects/content.effects';
import { adminContentReducer } from './store/reducers/content.reducers';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideHttpClient(withInterceptors([authHttpInterceptorFn])),
    provideAuth0({
      ...environment.auth0,
      httpInterceptor: {
        allowedList: [
          {
            uri: `${environment.api.serverUrl}/api/v1/*`,
          },
          {
            uri: `${environment.api.pdfReportUrl}/api/v1/*`,
          },
        ],
      },
    }),
    provideClientHydration(),
    provideAngularSvgIcon(),
    provideRouterStore(),
    provideStore({
      adminContent: adminContentReducer,
      router: routerReducer,
    }),
    provideEffects([ContentEffects, AssessmentsEffects]),
    provideAnimations(),
    provideStoreDevtools({ maxAge: 25, logOnly: isDevMode() }),
    provideAnimationsAsync(),
  ],
};
