<app-page [content]="content" />

<ng-template #content>

  <h1 class="text-bold m-t-5 m-b-3">Organisation reports</h1>

  <div>
    <br/>
    <p>
      <a routerLink="/home">Home</a>
    </p>
  </div>

  <div class="m-t-3">
    <app-power-bi-report
      [embedToken]="this.embedToken"
      [embedUrl]="this.embedUrl"
      [reportId]="this.reportId">
    </app-power-bi-report>
  </div>

</ng-template>
<support-widget />
