import { createReducer, on } from '@ngrx/store';
import {DataState, SaveState} from '../../models/common.models';
import {campaignDataActions, importInviteActions} from '../actions/campaign.actions';
import {CampaignDashboardsResponse} from '../../models/response-models';

/**
 * What are reducers?
 *
 * Reducers are pure functions in NgRx that handle how the state of an application changes in response
 * to dispatched actions. They take two arguments: the current state and the dispatched action.
 * Based on the action type, reducers return a new state object while ensuring immutability.
 */

export const initialCampaignState: DataState<CampaignDashboardsResponse> = {
  data: null,
  loading: false,
  error: null,
};

export const campaignReducer = createReducer(
  initialCampaignState,
  on(campaignDataActions.getCampaignData, (state) => ({
    ...state,
    data: null,
    loading: true,
    error: null,
  })),
  on(campaignDataActions.getCampaignDataSuccess, (state, { campaignSummaryData }) => ({
    ...state,
    data: campaignSummaryData,
    loading: false,
  })),
  on(campaignDataActions.getCampaignDataFailure, (state, { error }) => ({
    ...state,
    data: null,
    loading: false,
    error,
  })),
);

export const initialImportState: SaveState = {
  loading: false,
  error: null,
};

export const campaignImportReducer = createReducer(
  initialImportState,
  on(importInviteActions.importInvite, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(importInviteActions.importInviteSuccess, (state) => ({
    ...state,
    loading: false,
  })),
  on(importInviteActions.importInviteDataFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
);
