<h2 class="m-l-3 m-t-3 m-b-3 text-medium">Invite</h2>

<div>
<form [formGroup]="form" (keydown.enter)="$event.preventDefault()" (ngSubmit)="onSubmit()" class="dialog-content">
   <div class="text-text-main">
    <app-text-input
      trackingId="external-id-input-landing"
      [config]="{
            label: 'Id',
            controlName: 'externalId',
            elementId: '1',
          }"
    >
    </app-text-input>
  </div>
  <div class="text-text-main">
    <app-text-input
      trackingId="full-name-input-landing"
      [config]="{ label: 'Name', controlName: 'name', elementId: '2' }"
    >
    </app-text-input>
  </div>
  <div class="text-text-main">
    <app-text-input
      trackingId="email-input-landing"
      [config]="{ label: 'Email address', controlName: 'email', elementId: '3' }"
    >
    </app-text-input>
  </div>

  <div *ngIf="submitted">

    <app-callout
      [config]="successCallout"
      trackingId="callout-invitation-submitted-success"
    >
      Invitation submitted successfully
    </app-callout>

  </div>
  <div *ngIf="!submitted">
    <mat-dialog-actions >
      <div class="p-r-2">
        <button (click)="onCancelClick()" class="secondary-action button-md button">Close</button>
      </div>

      <div class="action-button-block-sm-center-md">
        <app-button
          [config]="{ type: 'submit', disabled: form.invalid }"
          trackingId="admin-invite-form-submit"
          (clicked)="onSubmit()"
        >
          Send invitation
        </app-button>
      </div>
    </mat-dialog-actions>
  </div>

</form>
</div>
