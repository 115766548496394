import {Component, OnInit} from '@angular/core';
import {PowerBiReportComponent} from '../../components/power-bi-report/power-bi-report.component';
import {AuthService, User} from '@auth0/auth0-angular';
import {PowerBiService} from '../../services/power-bi.service';
import {Observable} from 'rxjs';
import {PageComponent} from '../../common/page/page.component';
import {RouterLink} from "@angular/router";
import {SupportWidgetComponent} from "../../components/support-widget/support-widget.component";

@Component({
  selector: 'app-report.page',
  standalone: true,
    imports: [
        PowerBiReportComponent,
        PageComponent,
        RouterLink,
        SupportWidgetComponent
    ],
  templateUrl: './report.page.component.html',
  styleUrl: './report.page.component.scss'
})
export class ReportPageComponent implements OnInit {
  protected user$: Observable<User | null | undefined>;
  embedToken = '';
  embedUrl = '';
  reportId = '';

  constructor(
    private readonly auth: AuthService,
    private powerBIService: PowerBiService
  ) {
    this.user$ = this.auth.user$;
  }

  ngOnInit(): void {
    this.powerBIService.getEmbedToken().subscribe((data) => {
      this.embedToken = data.embedToken;
      this.embedUrl = data.embedUrl;
      this.reportId = data.reportId;
    });
  }
}
