import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class JwtPayloadParserService {
  constructor() {}

  public hasPermission(token: string, permission: string): boolean {
    try {
      const payload = JSON.parse(window.atob(token?.split('.')[1]));
      return payload.permissions?.includes(permission) || false;
    } catch (error: any) {
      return false;
    }
  }

  public getCustomClaim(token: string, claimName: string): string {
    try {
      const payload = JSON.parse(window.atob(token?.split('.')[1]));
      return payload?.[claimName];
    } catch (error: any) {
      return '';
    }
  }
}
