<div class="campaign-container">
  <h2 *ngIf="this.showHeading" class="m-b-3 text-medium">{{this.heading}}</h2>

  <div class="result-container">
    @for (item of campaignSummary?.campaigns; track item.name) {
      <div class="row">
        <div class="segment first-segment">
          <h4>{{ item.name }}</h4>
          <div class="divider"></div>
        </div>
        <div class="content-segments">
          <div class="segment">
            <div class="segment-content second">
              <div class="segment-top">Invites sent</div>
              <div class="segment-bottom">{{ getFormattedNumber(item.invitesSent) }}</div>
            </div>
            <div class="divider"></div>
          </div>
          <div class="segment">
            <div class="segment-content third">
              <div class="segment-top">Responses</div>

              <div class="segment-bottom">
                <span>{{ getFormattedNumber(item.assessmentsCompleted) }}&nbsp;</span>
                <span class="percentage">({{ getPercentage(item.assessmentsCompleted, item.invitesSent) }})</span>
              </div>
            </div>
            <div class="divider"></div>
          </div>
          <div class="segment">
            <div class="segment-content fourth">
              <div class="segment-top">Support indicated</div>
              <div class="segment-bottom">
                <span>{{ getFormattedNumber(item.supportIndicated) }}&nbsp;</span>
                <span class="percentage">({{ getPercentage(item.supportIndicated, item.assessmentsCompleted) }})</span>
              </div>
            </div>
            <div class="divider"></div>
          </div>
          <div class="segment">
            <div class="segment-content fifth">
              <div class="segment-top">Last response</div>
              <div class="segment-bottom">
                <span *ngIf="getFormattedDaySpan(item.daysSinceLastResponse).count > 0"
                  >{{ getFormattedDaySpan(item.daysSinceLastResponse).count }}&nbsp;</span
                ><span class="suffix">{{ getFormattedDaySpan(item.daysSinceLastResponse).suffix }}</span>
              </div>
            </div>
          </div>
          <div *ngIf="this.showResponses" class="segment last-segment">
            <div class="segment-content sixth">
              <div class="segment-top"></div>
              <div>
                <a [routerLink]="['/assessments']" [queryParams]="{code: item.code}" >View responses</a>
              </div>

          </div>
        </div>
        </div>
      </div>
    } @empty {
      <div class="grid-table">
        <div class="cell">There are no items.</div>
        <div class="cell"></div>
      </div>
    }
  </div>

  @if (this.showGuides) {
    <div>
      <h2 class="m-t-5 m-b-3 text-medium">Guides</h2>
      <ul class="guide-block">
        @for (item of (this.campaignSummary)?.guideUrls; track item.fileName) {
          <li class="m-b-1">
            {{ item.fileName }}
            <a
              href="{{ constructUrl(item) }}"
              target="_blank"
            >(download {{ item.fileExtension | uppercase }})</a
            >
          </li>
        }
      </ul>
    </div>
  } @else {
    <div class="m-t-5 m-b-3"></div>
  }
</div>
