import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { combineLatest, map } from 'rxjs';
import { Permissions } from '../../constants/auth-permissions';
import { JwtPayloadParserService } from '../../services/jwt-payload-parser.service';

@Component({
  selector: 'support-widget',
  standalone: true,
  imports: [],
  templateUrl: './support-widget.component.html',
  styleUrl: './support-widget.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class SupportWidgetComponent implements OnInit {
  private readonly widgetSource: string = "https://jsd-widget.atlassian.com/assets/embed.js";

  constructor(
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private authService: AuthService,
    private readonly jwtParser: JwtPayloadParserService,
  ) {}

  ngOnInit() {
    if (document.querySelector(`script[src="${this.widgetSource}"]`)) {
      return;
    }

    try {
      const canRequestSupport$ = this.authService
        .getAccessTokenSilently()
        .pipe(map((token) => (token && this.jwtParser.hasPermission(token, Permissions.sendSupportRequests)) || false));
      combineLatest([this.authService.isAuthenticated$, canRequestSupport$]).subscribe(
        ([isAuthenticated, isEnabled]) => {
          if (isAuthenticated && isEnabled) {
            const s = this.renderer2.createElement('script');
            this.renderer2.setAttribute(s, 'data-jsd-embedded', '');
            this.renderer2.setAttribute(s, 'data-key', '5702f76d-0778-4a82-b174-d0ec9b825e4a');
            this.renderer2.setAttribute(s, 'data-base-url', 'https://jsd-widget.atlassian.com');
            s.src = this.widgetSource;
            s.async = true;
            s.onload = function () {
              var DOMContentLoaded_event = document.createEvent('Event');
              DOMContentLoaded_event.initEvent('DOMContentLoaded', true, true);
              window.document.dispatchEvent(DOMContentLoaded_event);
            };
            this.renderer2.appendChild(this._document.body, s);
          }
        },
      );
    } catch (e) {
      console.log(e);
    }
  }
}
