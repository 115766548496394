import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ContentConfig } from './content.models';
import { AptemComponent } from '../../../aptem-component-library/components/common/directives/aptem-component/aptem-component';

@Component({
  selector: 'app-content',
  standalone: true,
  imports: [NgIf],
  templateUrl: './content.component.html',
  styleUrl: './content.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentComponent extends AptemComponent<ContentConfig> {
  @Input() key: string = '';
  @Input() value: string | null = null;
  @Input() loading: boolean = false;
  protected defaultConfig: Required<ContentConfig> = {
    elementId: null,
  };
  constructor(private sanitizer: DomSanitizer) {
    super();
  }
  getSanitisedContent(): SafeHtml {
    if (this.value !== null) {
      return this.sanitizer.bypassSecurityTrustHtml(this.value);
    }

    return '';
  }
}
