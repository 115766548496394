import {inject, Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {InviteDialogComponent} from '../components/invite-dialog/invite-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class InviteDialogService {
  private dialog = inject(MatDialog);

  constructor() {
  }

  openDialog(): Observable<any> {
    const dialogRef = this.dialog.open(InviteDialogComponent, {
      maxHeight: '540px',
      height: 'auto',
      width: '450px',
      panelClass: 'custom-dialog-panel',
    });

    return dialogRef.afterClosed();
  }
}
