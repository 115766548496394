import { createReducer, on } from '@ngrx/store';
import { DataState } from '../../models/common.models';
import {Assessment, AssessmentListResult} from '../../models/assessments.models';
import { assessmentsDataActions } from '../actions/assessments.actions';

/**
 * What are reducers?
 *
 * Reducers are pure functions in NgRx that handle how the state of an application changes in response
 * to dispatched actions. They take two arguments: the current state and the dispatched action.
 * Based on the action type, reducers return a new state object while ensuring immutability.
 */

export const initialAssessmentsState: DataState<AssessmentListResult> = {
  data: null,
  loading: false,
  error: null,
};

export const assessmentsReducer = createReducer(
  initialAssessmentsState,
  on(assessmentsDataActions.getAssessmentsData, (state) => ({
    ...state,
    data: null,
    loading: true,
    error: null,
  })),
  on(assessmentsDataActions.getAssessmentsDataSuccess, (state, { assessments, total }) => ({
    ...state,
    data: {assessments: assessments, total: total},
    loading: false,
  })),
  on(assessmentsDataActions.getAssessmentsDataFailure, (state, { error }) => ({
    ...state,
    data: null,
    loading: false,
    error,
  })),
);
