import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from '@auth0/auth0-angular';
import { PageComponent } from '../../common/page/page.component';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { RoutePathParameters } from '../../models/route-models';

@Component({
  selector: 'login',
  standalone: true,
  imports: [CommonModule, PageComponent],
  templateUrl: './login-page.component.html',
})
export class LoginPageComponent {
  constructor(
    @Inject(DOCUMENT) public document: Document,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router) {

  }

  ngOnInit() {
    this.auth.isAuthenticated$.subscribe(isAuthenticated => {
      if (!isAuthenticated) {
        var params = this.getRoutePathParams(this.route.snapshot);

        this.auth.loginWithRedirect({
          authorizationParams: {
            invitation: params.inviteId,
            organization: params.orgId
          }
        });
      }
      else {
        this.router.navigate(['/home']);
      }
    });
  }

  private getRoutePathParams(routeSnapshot: ActivatedRouteSnapshot): RoutePathParameters {
    const inviteId = routeSnapshot.queryParams['invitation'];
    const orgId = routeSnapshot.queryParams['organization'];

    return { inviteId, orgId };
  }
}
