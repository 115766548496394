<div>
  <div>
    <br />
    <p>
      <a routerLink="/home">Home</a>
    </p>
  </div>
  <div>
    <h1 class="text-bold m-t-5 m-b-3">Campaigns</h1>
    <h2 class="m-b-3 text-medium">Responses</h2>
  </div>
  <div>
    <campaign-results
      [campaignSummary]="this.campaignSummary$ | async"
      [canViewEngagements]="this.engagementPermission$ | async"
      [showHeading]=false
      [showResponses]=false
      [showGuides]=false
    ></campaign-results>
  </div>

  <div class="actions m-b-3">
    <div *ngIf="this.inviteFormPermission$ | async">
      <button
        class="button button-primary p-r-2 p-l-2 invite-button"
        (click)="openDialog()">
        <svg-icon
          src="assets/images/icon-plus.svg"
          data-ua-id="invite-icon"
          class="invite-icon"
        ></svg-icon>Invite</button>
    </div>

    <div class="right-buttons">
      <button
        class="primary-action"
        debounceClick (debouncedClick)="getCampaignResults()"
      >
        <svg-icon
          src="assets/images/icon-download.svg"
          data-ua-id="download-icon"
          class="download-icon"
        ></svg-icon>
        Download results
      </button>

      <button
        *ngIf="engagementPermission$ | async"
        class="secondary-action"
        debounceClick (debouncedClick)="downloadEngagementReport()"
      >
        <svg-icon
          src="assets/images/icon-download.svg"
          data-ua-id="download-icon"
          class="download-icon"
        ></svg-icon>
        Download engagements
      </button>
    </div>
  </div>

  @if (result$ | async; as result) {
    @if (total$ | async; as total) {
      <app-table
        [columns]="columns"
        [data]="result"
        [totalRecords]="total"
        [pageIndex]="currentPageIndex"
        [pageSize]="currentPageSize"
        [downloadFunction]="downloadIndividualPdf"
        (pageChange)="onPageChange($event)"
      />
    } @else {
      <div class="grid-table">
        <div class="cell">There are no responses.</div>
        <div class="cell"></div>
      </div>
    }
  } @else {
    <div class="grid-table">
      <div class="cell">There are no responses.</div>
      <div class="cell"></div>
    </div>
  }
</div>
