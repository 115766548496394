import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {map, Observable} from 'rxjs';
import { environment } from '../../environments/environment';
import { AdminContentsResponse, CampaignDashboardsResponse } from '../models/response-models';
import { ContentType } from '../constants/content-types'
import { AssessmentListResponse, AssessmentListResult } from '../models/assessments.models';
import {RegistrationRequest} from '../models/invite-user.models';

@Injectable({
  providedIn: 'root',
})
export class AdminPortalApiService {
  private baseUrl: string = environment.api.serverUrl;

  constructor(private httpClient: HttpClient) {}

  public getDashboards(): Observable<CampaignDashboardsResponse> {
    return this.httpClient.get<CampaignDashboardsResponse>(`${this.baseUrl}/api/v1/dashboards`);
  }

  public getCampaignResults(campaignCode: string): Observable<Blob> {
    return this.httpClient.get(`${this.baseUrl}/api/v1/campaigns/${campaignCode}/reports`, {
      responseType: 'blob',
    });
  }

  public getEngagementReport(campaignCode: string): Observable<Blob> {
    return this.httpClient.get(`${this.baseUrl}/api/v1/campaigns/${campaignCode}/engagements`, {
      responseType: 'blob',
    });
  }

  public getAdminContentByKey(): Observable<AdminContentsResponse> {
    return this.httpClient.get<AdminContentsResponse>(
      `${this.baseUrl}/api/v1/admin-contents?contentType=${ContentType.Web}`,
    );
  }

  public getCampaignAssessments(campaignCode: string, pageIndex: number, pageSize: number): Observable<AssessmentListResult> {
      return this.httpClient.get<AssessmentListResponse>(`${this.baseUrl}/api/v1/campaigns/${encodeURIComponent(campaignCode)}/assessments?pageNumber=${pageIndex+1}&pageSize=${pageSize}`)
        .pipe(map(response => ({
          assessments: response.assessments,
          total: response.total
        })));
  }

  public importInvitation(
    campaignCode: string,
    externalId: string | null,
    name: string,
    email: string,
  ): Observable<any> {
    const registrationRequest: RegistrationRequest = { email: email, fullName: name, externalId: externalId };

    return this.httpClient.post(
      `${this.baseUrl}/api/v1/campaigns/${campaignCode}/import-invitations`,
      registrationRequest,
      { observe: 'response' },
    );
  }

}
