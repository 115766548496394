import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Assessment } from '../../models/assessments.models';

export const assessmentsPageActions = createActionGroup({
  source: 'assessments pages',
  events: {
    'init assessments index page': emptyProps(),
    'init assessments individual page': emptyProps(),
  },
});

export const assessmentsDataActions = createActionGroup({
  source: 'assessment data',
  events: {
    'get assessments data': props<{campaignCode: string, pageIndex: number, pageSize: number }>(),
    'get assessments data success': props<{ assessments: Assessment[], total: number }>(),
    'get assessments data failure': props<{ error: string }>(),
  },
});
