import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { AdminPortalApiService } from '../../services/admin-portal-api.service';
import { setAdminContentByKeyFailure, setAdminContents, setAdminContentSuccess } from '../actions/content.actions';

@Injectable()
export class ContentEffects {
  constructor(
    private actions$: Actions,
    private readonly apiService: AdminPortalApiService,
  ) {}

  getAdminContentByKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setAdminContents),
      switchMap(() =>
        this.apiService.getAdminContentByKey().pipe(
          map((response) =>
            setAdminContentSuccess({
              content: response.contents,
            }),
          ),
          catchError((error) => of(setAdminContentByKeyFailure({ error }))),
        ),
      ),
    ),
  );
}
