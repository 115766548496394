import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PowerBiService {
  private baseUrl: string = environment.api.serverUrl;

  constructor(private http: HttpClient) {}

  getEmbedToken(): Observable<{ embedToken: string; embedUrl: string; reportId: string }> {
    return this.http.get<{ embedToken: string; embedUrl: string; reportId: string }>(`${this.baseUrl}/api/v1/power-bi-token`);
  }
}
