import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import * as powerbi from 'powerbi-client';

@Component({
  selector: 'app-power-bi-report',
  standalone: true,
  imports: [],
  templateUrl: './power-bi-report.component.html',
  styleUrl: './power-bi-report.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class PowerBiReportComponent implements OnChanges {
  @Input() embedToken!: string;
  @Input() embedUrl!: string;
  @Input() reportId!: string;

  @ViewChild('embedContainer', { static: true }) embedContainer!: ElementRef;

  private powerbiService = new powerbi.service.Service(powerbi.factories.hpmFactory, powerbi.factories.wpmpFactory, powerbi.factories.routerFactory);

  ngOnChanges(changes: SimpleChanges): void {
    if (this.embedToken && this.embedUrl && this.reportId) {
      this.embedReport();
    }
  }

  private embedReport() {
    const config: powerbi.IEmbedConfiguration = {
      type: 'report',
      id: this.reportId,
      embedUrl: this.embedUrl,
      accessToken: this.embedToken,
      tokenType: powerbi.models.TokenType.Embed,
      settings: {
        panes: { filters: { visible: false }, pageNavigation: { visible: false } },
      }
    };

    this.powerbiService.embed(this.embedContainer.nativeElement, config);
  }
}
