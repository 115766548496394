import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { SvgIconComponent } from 'angular-svg-icon';
import { AptemComponent } from '../common/directives/aptem-component/aptem-component';
import { TextInputConfig } from './text-input.models';

@Component({
  selector: 'app-text-input',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SvgIconComponent],
  templateUrl: './text-input.component.html',
  styleUrl: './text-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextInputComponent extends AptemComponent<TextInputConfig> implements OnInit {
  @Output() public focused: EventEmitter<null> = new EventEmitter();
  @Output() public changed: EventEmitter<null> = new EventEmitter();
  protected control: FormControl;

  ngOnInit(): void {
    this.control = this.formGroupDirective.control.get(this.getConfig.controlName!) as FormControl;
  }

  constructor(protected formGroupDirective: FormGroupDirective) {
    super();
  }

  protected defaultConfig: Required<TextInputConfig> = {
    color: 'primary',
    disabled: false,
    elementId: null,
    size: 'lg',
    label: '',
    controlName: '',
    validationMessage: ''
  };

  protected handleFocus(): void {
    const { disabled } = this.getConfig;

    if (!disabled) {
      this.focused.emit();
    }
  }

  protected handleChange(): void {
    const { disabled } = this.getConfig;

    if (!disabled) {
      this.changed.emit();
    }
  }
}
