export interface AssessmentListResponse {
  assessments: Assessment[];
  total: number;
}

export interface Assessment {
  assessmentId: number | null,
  invitationId: number,
  externalId: string,
  invitationSentDate: Date,
  invitationStatus: InvitationStatus,
  assessmentStartDate: Date | null,
  assessmentStatus: AssessmentStatus | null,
  assessmentCompletedDate: Date | null,
  overallResult: string | null,
  overallScore: number | null,
  qualityScorePercentage: number | null,
  symbolsResult: string | null,
  symbolsConsolidatedScore: number | null,
  similarWordsResult: string | null,
  similarWordsConsolidatedScore: number | null,
  numberRecallResult: string | null,
  numberRecallConsolidatedScore: number | null,
  pictureCompletionResult: string | null,
  pictureCompletionConsolidatedScore: number | null,
  fakeWordsResult: string | null,
  fakeWordsConsolidatedScore: number | null,
  missingNumbersResult: string | null,
  missingNumbersConsolidatedScore: number | null,
  objectsResult: string | null,
  objectsConsolidatedScore: number | null,
  shapesAndColoursResult: string | null,
  shapesAndColoursConsolidatedScore: number | null
}

export interface AssessmentListViewModel {
  assessmentId: number | null,
  externalId: string,
  status: string,
  sent: Date,
  completed: Date | null,
  quality: number | null,
  supportIndicated: (string | null)[],
  download: string
}

export enum InvitationStatus {
  'Unsent',
  'Sending',
  'Sent',
  'FailedToSend',
  'Received',
  'Opened',
  'Expired',
  'Completed',
}

export enum AssessmentStatus {
  'Not Started',
  'In Progress',
  'Completed'
}

export interface AssessmentListResult {
  assessments: Assessment[];
  total: number;
}
