import { getRouterSelectors } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DataState } from '../../models/common.models';
import {CampaignDashboardsResponse} from '../../models/response-models';

/**
 * What are selectors?
 *
 * In NgRx, selectors are pure functions used to query and derive data from the store's state.
 * They allow components (and others) to access specific parts of the state or transform data
 * into a format suitable for the UI. Selectors improve reusability, centralise state access
 * logic, and enhance performance by using memoization to avoid unnecessary recalculations.
 */

/**
 * Router selectors
 *
 * NgRx provides a router store which is automatically populated by Angular + NgRx.
 *
 * Here, we've created a selector to pull the :id url param for individual learner pages.
 */

export const { selectQueryParam } = getRouterSelectors();

/**
 * Primary state selectors
 *
 * We have an established practise of using a DataState<T> interface which offers 3 properties
 * for the data itself, the loading status and any errors.
 */

export const selectCampaignState = createFeatureSelector<DataState<CampaignDashboardsResponse>>('campaignSummary');

export const selectCampaignData = createSelector(selectCampaignState, (state) => state?.data ?? null );

export const selectCampaignLoading = createSelector(selectCampaignState, (state) => state.loading);

export const selectCampaignError = createSelector(selectCampaignState, (state) => state.error);

export const selectQueryParamCode = createSelector(selectQueryParam('code'), (code) => code);

export const selectCampaignSummaryByCode = createSelector(
  selectQueryParamCode,
  selectCampaignData,
  (code, campaignData) => {
    if (!campaignData || !campaignData.campaigns) return null; // Ensure it matches the expected type

    const filteredCampaigns = campaignData.campaigns.filter((c) => c.code === code);

    return filteredCampaigns.length > 0
      ? { ...campaignData, campaigns: filteredCampaigns }
      : null; // Return null when no matching campaign is found
  }
);

