import { getRouterSelectors } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DataState } from '../../models/common.models';
import {AssessmentListResult, AssessmentStatus, InvitationStatus} from '../../models/assessments.models';

/**
 * What are selectors?
 *
 * In NgRx, selectors are pure functions used to query and derive data from the store's state.
 * They allow components (and others) to access specific parts of the state or transform data
 * into a format suitable for the UI. Selectors improve reusability, centralise state access
 * logic, and enhance performance by using memoization to avoid unnecessary recalculations.
 */

/**
 * Router selectors
 *
 * NgRx provides a router store which is automatically populated by Angular + NgRx.
 *
 * Here, we've created a selector to pull the :id url param for individual learner pages.
 */

export const { selectRouteParam } = getRouterSelectors();

export const selectRouteParamCode = createSelector(selectRouteParam('code'), (code) => code);

/**
 * Primary state selectors
 *
 * We have an established practise of using a DataState<T> interface which offers 3 properties
 * for the data itself, the loading status and any errors.
 */

export const selectAssessmentsState = createFeatureSelector<DataState<AssessmentListResult>>('assessments');

export const selectAssessmentsData = createSelector(selectAssessmentsState, (state) => state.data);

export const selectAssessmentsLoading = createSelector(selectAssessmentsState, (state) => state.loading);

export const selectAssessmentsError = createSelector(selectAssessmentsState, (state) => state.error);

export const selectAssessmentResultList = createSelector(selectAssessmentsState, (state) =>
  state.data?.assessments.map(a => ({
    download: '',
    assessmentId: a.assessmentId,
    externalId: a.externalId,
    status: a.assessmentStatus ? AssessmentStatus[a.assessmentStatus] : InvitationStatus[a.invitationStatus],
    sent: a.invitationSentDate,
    completed: a.assessmentCompletedDate,
    quality: a.qualityScorePercentage,
    supportIndicated: [
      a.symbolsResult === 'Support Indicated' ? 'Symbols' : null,
      a.similarWordsResult === 'Support Indicated' ? 'Similar Words' : null,
      a.numberRecallResult === 'Support Indicated' ? 'Number Recall' : null,
      a.pictureCompletionResult === 'Support Indicated' ? 'Picture Completion' : null,
      a.fakeWordsResult === 'Support Indicated' ? 'Fake Words' : null,
      a.missingNumbersResult === 'Support Indicated' ? 'Missing Numbers' : null,
      a.objectsResult === 'Support Indicated' ? 'Objects' : null,
      a.shapesAndColoursResult === 'Support Indicated' ? 'Shapes & Colours' : null,
    ].filter(Boolean) // Remove null values
  })) ?? []
);

export const selectTotalAssessments = createSelector(selectAssessmentsState, (state) => state.data?.total);
