import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { AdminPortalApiService } from '../../services/admin-portal-api.service';
import { assessmentsDataActions } from '../actions/assessments.actions';

@Injectable()
export class AssessmentsEffects {
  private actions$ = inject(Actions);

  getAssessmentsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(assessmentsDataActions.getAssessmentsData),
      exhaustMap(({campaignCode, pageIndex, pageSize}) =>
        this.assessmentsService.getCampaignAssessments(campaignCode, pageIndex, pageSize).pipe(
          map(result => assessmentsDataActions.getAssessmentsDataSuccess({assessments: result.assessments, total: result.total})),
          catchError((error) => of(assessmentsDataActions.getAssessmentsDataFailure({ error: error.message }))),
        ),
      ),
    ),
  );

  constructor(
    private assessmentsService: AdminPortalApiService,
    private store: Store,
  ) {}
}
