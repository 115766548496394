import { AsyncPipe, NgIf } from '@angular/common';
import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import { SvgIconComponent } from 'angular-svg-icon';
import {filter, map, Observable, tap} from 'rxjs';
import { take } from 'rxjs/operators';
import { TableComponent } from '../../common/components/table/table.component';
import { TableColumn } from '../../common/components/table/table.models';
import { Permissions } from '../../constants/auth-permissions';
import { DebounceClickDirective } from '../../directives/debounce-click.directive';
import { AssessmentListViewModel } from '../../models/assessments.models';
import { CampaignDashboardsResponse } from '../../models/response-models';
import { AdminPortalApiService } from '../../services/admin-portal-api.service';
import { JwtPayloadParserService } from '../../services/jwt-payload-parser.service';
import { PdfReportApiService } from '../../services/pdf-report-api.service';
import { assessmentsDataActions } from '../../store/actions/assessments.actions';
import {campaignDataActions, importInviteActions} from '../../store/actions/campaign.actions';
import {
  selectAssessmentResultList,
  selectAssessmentsError,
  selectAssessmentsLoading,
  selectTotalAssessments,
} from '../../store/selectors/assessments.selectors';
import { selectCampaignSummaryByCode } from '../../store/selectors/campaign.selectors';
import { CampaignResultsComponent } from '../campaign-results/campaign-results.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {InviteDialogService} from '../../services/invite-dialog.service';

@Component({
  selector: 'app-assessment-results',
  standalone: true,
  imports: [
    AsyncPipe,
    TableComponent,
    RouterLink,
    CampaignResultsComponent,
    SvgIconComponent,
    NgIf,
    DebounceClickDirective,
  ],
  templateUrl: './assessment-results.component.html',
  styleUrl: './assessment-results.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentResultsComponent implements OnInit {
  // TOOD : should this component to a containers folder? Review against ngrx-demo
  public result$ = this.store.select(selectAssessmentResultList);
  public total$ = this.store.select(selectTotalAssessments);
  public loading$: Observable<boolean> = this.store.select(selectAssessmentsLoading);
  public error$: Observable<string | null> = this.store.select(selectAssessmentsError);
  public columns: TableColumn<AssessmentListViewModel>[] = [
    { key: 'externalId', label: 'Id', type: 'string' },
    { key: 'status', label: 'Status', type: 'string' },
    { key: 'sent', label: 'Sent', type: 'date' },
    { key: 'completed', label: 'Completed', type: 'date' },
    { key: 'quality', label: 'Quality Score', type: 'string' },
    { key: 'supportIndicated', label: 'Support Indicated', type: 'string' },
    { key: 'download', label: '', type: 'button' },
  ];
  protected currentPageIndex: number;
  protected currentPageSize: number;
  protected campaignCode: string;
  protected engagementPermission$: Observable<boolean>;
  protected inviteFormPermission$: Observable<boolean>;
  protected campaignSummary$: Observable<CampaignDashboardsResponse | null> =
    this.store.select(selectCampaignSummaryByCode);
  formData: any = null;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private readonly apiService: AdminPortalApiService,
    private readonly pdfReportService: PdfReportApiService,
    private authService: AuthService,
    private jwtParser: JwtPayloadParserService,
    private breakpointObserver: BreakpointObserver,
    private inviteDialogService: InviteDialogService
  ) {}

  ngOnInit(): void {
    this.engagementPermission$ = this.authService
      .getAccessTokenSilently()
      .pipe(map((token) => (token && this.jwtParser.hasPermission(token, Permissions.readEngagements)) || false));

    this.inviteFormPermission$ = this.authService
      .getAccessTokenSilently()
      .pipe(map((token) => (token && this.jwtParser.hasPermission(token, Permissions.sendInvitesForm)) || false));

    let campaignCodeFromRoute$ = this.route.queryParams.pipe(map((params) => params['code']));

    campaignCodeFromRoute$.subscribe((campaignCode) => {
      this.campaignCode = campaignCode;
    });

    this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Tablet]).subscribe((result) => {
      if (result.matches) {
        this.loadAssessments(0, 5);
      } else {
        this.loadAssessments(0, 25);
      }
    });

    this.campaignSummary$.pipe(take(1)).subscribe((campaignSummary) => {
      if (!campaignSummary) {
        this.store.dispatch(campaignDataActions.getCampaignData());
      }
    });
  }

  onPageChange(event: PageEvent) {
    this.loadAssessments(event.pageIndex, event.pageSize);
  }

  openDialog(): void {
    this.inviteDialogService.openDialog().pipe(
      filter(result => !!result),
        tap((formData) =>
          this.store.dispatch(importInviteActions.importInvite({
              campaignCode: this.campaignCode,
              externalId: formData.externalId,
              name: formData.name,
              email: formData.email
            }))
        )
    ).subscribe();
  }

  protected getCampaignResults() {
    this.apiService.getCampaignResults(this.campaignCode).subscribe((blob) => {
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      a.download = `${this.campaignCode}_${new Date().toISOString()}.csv`;
      a.click();
      URL.revokeObjectURL(objectUrl);
    });
  }

  protected downloadEngagementReport() {
    this.apiService.getEngagementReport(this.campaignCode).subscribe((blob) => {
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      a.download = `engagement_${this.campaignCode}_${new Date().toISOString()}.csv`;
      a.click();
      URL.revokeObjectURL(objectUrl);
    });
  }

  downloadIndividualPdf = (assessmentId: number, externalId: string) => {
    this.pdfReportService.getIndividualReportResults(this.campaignCode, assessmentId).subscribe((blob) => {
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      a.download = `${externalId}_${this.campaignCode}_assessment_result_${new Date().toISOString()}.pdf`;
      a.click();
      URL.revokeObjectURL(objectUrl);
    });
  };

  private loadAssessments(pageIndex: number, pageSize: number) {
    this.store.dispatch(
      assessmentsDataActions.getAssessmentsData({ campaignCode: this.campaignCode, pageIndex, pageSize }),
    );
    this.currentPageIndex = pageIndex;
    this.currentPageSize = pageSize;
  }
}
