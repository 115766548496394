<div class="table-responsive">
  <table mat-table [dataSource]="data">
    <!-- Dynamic Column Definitions -->
    <ng-container *ngFor="let column of columns" [matColumnDef]="column.key">
      <th mat-header-cell *matHeaderCellDef>{{ column.label }}</th>
      <td mat-cell *matCellDef="let element">
        @if (column.key === 'supportIndicated') {
          @if (element['completed'] && element[column.key]?.length > 0) {
          <div class="support-indicated">
            <span *ngFor="let item of element[column.key]">{{ item }}</span>
          </div>
          } @else {
            None
          }
        } @else if (column.key === 'download') {
            <!-- Show download button only if 'completed' has a value -->
            @if (element['completed']) {
              <button mat-button debounceClick (debouncedClick)="downloadReport(element)">
                Download report
              </button>
            }
        } @else {
          {{ getFormattedValue(element[column.key], column.type) }}@if (column.key === 'quality' && element[column.key]) {%}
        }
      </td>
    </ng-container>

    <!-- Header & Row Rendering -->
    <tr mat-header-row *matHeaderRowDef="getColumnKeys()"></tr>
    <tr mat-row *matRowDef="let row; columns: getColumnKeys();"></tr>
  </table>
  <mat-paginator
    [length]="totalRecords"
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    [pageSizeOptions]="[5, 10, 25, 50]"
    showFirstLastButtons
    (page)="handlePageEvent($event)"></mat-paginator>
</div>
