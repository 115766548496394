import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CampaignDashboardsResponse } from '../../models/response-models';

export const campaignSummaryActions = createActionGroup({
  source: 'campaign summary',
  events: {
    'init all campaign summary': emptyProps(),
    'init individual campaign summary': emptyProps(),
  },
});

export const campaignDataActions = createActionGroup({
  source: 'campaign data',
  events: {
    'get campaign data': emptyProps(),
    'get campaign data success': props<{ campaignSummaryData: CampaignDashboardsResponse }>(),
    'get campaign data failure': props<{ error: string }>(),
  },
});

export const importInviteActions = createActionGroup({
  source: 'import invite form',
  events: {
    'import invite': props<{campaignCode: string, externalId: string, name: string, email: string }>(),
    'import invite success': emptyProps(),
    'import invite data failure': props<{ error: string }>(),
  },
});
