import { createSelector } from '@ngrx/store';
import { AdminContentState } from '../models/content-models';

export const selectAdminContent = (state: any): AdminContentState => state.adminContent;

export const selectAdminContentState = createSelector(selectAdminContent, (state) => state?.content);

export const selectAdminContentByKey = (key: string) =>
  createSelector(selectAdminContentState, (state) => {
    const item = state?.data?.find((i) => i.key === key);
    return item?.value || null;
  });
