import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {MatDialogActions, MatDialogRef} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {ButtonComponent} from '../../../aptem-component-library/components/button/button.component';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {TextInputComponent} from '../../../aptem-component-library/components/text-input/text-input.component';
import {CalloutComponent} from '../../../aptem-component-library/components/callout/callout.component';
import {CalloutConfig} from '../../../aptem-component-library/components/callout/callout.models';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-invite-dialog',
  standalone: true,
  imports: [
    ButtonComponent,
    FormsModule,
    MatDialogActions,
    TextInputComponent,
    ReactiveFormsModule,
    CalloutComponent,
    NgIf,
    CalloutComponent
  ],
  templateUrl: './invite-dialog.component.html',
  styleUrl: './invite-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InviteDialogComponent {
  readonly dialogRef = inject(MatDialogRef<InviteDialogComponent>);
  protected externalIdLabelKey: string = 'register-externalid-label';
  protected externalIdLabelValue$: Observable<string | null>;
  protected externalIdValidationMessageKey: string = 'registration-externalId-validationMessage';
  protected externalIdValidationMessageValue$: Observable<string | null>;
  protected showExternalIdField$: Observable<boolean | null>;
  public form: FormGroup;
  protected successCallout: CalloutConfig = { color: 'success' };
  public submitted: boolean = false;

  constructor() {
    this.form = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(500),
        Validators.pattern(/^[a-zA-Z0-9-' ]+$/),
      ]),
      email: new FormControl('', [Validators.required, Validators.maxLength(500), Validators.email]),
      externalId: new FormControl('', [Validators.required, Validators.maxLength(250), Validators.pattern(/^[a-zA-Z0-9-]+$/)]),
    });

  }
  onCancelClick(): void {
    this.form.reset({}, { emitEvent: false }); // Reset form without triggering validation events
    this.dialogRef.close(null);
  }

  onSubmit() {
    if (this.form.valid) {
      this.submitted = true;
      setTimeout(() => {
        this.dialogRef.close(this.form.value);
      }, 2000);    }
  }
}
