import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {JwtPayloadParserService} from '../services/jwt-payload-parser.service';
import {Permissions} from '../constants/auth-permissions';

@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private readonly jwtParser: JwtPayloadParserService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const requiredPermission = route.data['permission']; // Get required permission from route data

    return this.authService.getAccessTokenSilently().pipe(
      take(1),
      map(token => {
        const hasPermission = token && this.jwtParser.hasPermission(token, requiredPermission) || false;

        if (!hasPermission) {
          this.router.navigate(['/home']); // Redirect if permission is missing
          return false;
        }

        return true;
      })
    );
  }
}
