import { NgForOf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { DebounceClickDirective } from '../../../directives/debounce-click.directive';
import { TableColumn } from './table.models';

/**
 * We've created a reusable table component agnostic of business logic to specific
 * data sets, such as adult learners or child learners. This ensures the component
 * can be used in multiple contexts without losing its reusability. If the table were
 * designed around specific logic, it would limit its ability to adapt to other
 * scenarios, leading to duplication.
 *
 * Components don't have store access.
 */

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrl: 'table.component.scss',
  imports: [MatTableModule, MatPaginatorModule, NgForOf, MatButton, DebounceClickDirective, MatProgressSpinnerModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class TableComponent<T> {
  @Input() public columns: TableColumn<T>[] = [];
  @Input() totalRecords: number = 0;
  @Input() data: T[] = [];
  @Input() pageIndex: number = 0;
  @Input() pageSize: number = 25;
  @Input() downloadFunction!: (id: number, externalId: string) => void;
  @Output() pageChange = new EventEmitter<PageEvent>();

  constructor() { }

  protected getColumnKeys(): string[] {
    return this.columns.map((c) => c.key as string);
  }

  protected formatDate(date: Date | string): string {
    const parsedDate = date instanceof Date ? date : new Date(date);
    return parsedDate.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' });
  }

  protected getFormattedValue(value: any, columnType: string | null): string {
    if (columnType === 'date' && value) {
      return this.formatDate(value);
    }
    return value ?? '-';
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  handlePageEvent(event: PageEvent) {
    this.pageChange.emit(event);
  }

  downloadReport(element: any): void {
    if (this.downloadFunction !== null) {
      this.downloadFunction(element.assessmentId, element.externalId);
    }
  }
}
