<div>
  <div>
    <label
      [for]="getConfig.elementId"
      class="m-l-1 m-b-1 text-sm font-weight-medium"
      >{{ getConfig.label }}</label
    >
    <label class="text-sm font-weight-medium required-indicator">*</label>
  </div>

  <div class="input-wrapper">
    <input
      [attr.id]="getConfig.elementId || null"
      [attr.data-ua-id]="trackingId"
      [disabled]="getConfig.disabled!"
      [ngClass]="['user-input-' + getConfig.color, 'user-input-' + getConfig.size]"
      class="user-input"
      type="search"
      [formControl]="control"
      (focus)="handleFocus()"
      (change)="handleChange()"
    />
  </div>
  <div class="validation-wrapper">
  @if (control.touched && control.invalid) {
    <div class="invalid-indicator">
      @if (getConfig.validationMessage) {
      <label class="m-l-1 text-xs font-weight-medium invalid-text"
        >{{ getConfig.validationMessage}}.</label
      >
      } @else {
      <label class="m-l-1 text-xs font-weight-medium invalid-text"
        >Please enter your {{ getConfig.label | lowercase }}.</label
      >
      }
    </div>
  }
  </div>
</div>
<ng-content />
