import { Directive, ElementRef, EventEmitter, HostListener, Inject, Output, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from '../models/app-config';

@Directive({
  selector: '[debounceClick]',
  standalone: true,
})
export class DebounceClickDirective {
  @Output() debouncedClick = new EventEmitter<Event>();

  private clicks = new Subject<Event>();
  private destroy$ = new Subject<void>();

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    @Inject(APP_CONFIG) private readonly config: AppConfig,
  ) {}

  ngOnInit() {
    this.clicks
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        this.debouncedClick.emit(event);
        this.disableButton();
      });
  }

  @HostListener('click', ['$event'])
  handleClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }

  private disableButton() {
    const button = this.elementRef.nativeElement as HTMLButtonElement;
    this.renderer.setAttribute(button, 'disabled', 'true');
    setTimeout(() => {
      this.renderer.removeAttribute(button, 'disabled');
    }, this.config.debounceTime);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
