import { createAction, props } from '@ngrx/store';
import { StoreConstants } from '../constants/store-constants';
import { AdminContent } from '../models/content-models';

export const setAdminContents = createAction(`${StoreConstants.STORE_KEY} Load Content`);

export const setAdminContentSuccess = createAction(
  `${StoreConstants.STORE_KEY} Load Content Success`,
  props<{ content: AdminContent }>(),
);

export const setAdminContentByKeyFailure = createAction(
  `${StoreConstants.STORE_KEY} Load Content Failure`,
  props<{ error: any }>(),
);
