import { Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { CampaignResultsComponent } from './components/campaign-results/campaign-results.component';
import { ErrorComponent } from './components/error/error.component';
import { Permissions } from './constants/auth-permissions';
import { PermissionsGuard } from './guards/permissions.guard';
import { AssessmentsPageComponent } from './pages/assessments/assessments.page.component';
import { HomePageComponent } from './pages/home/home.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { LogoutPageComponent } from './pages/logout/logout.component';
import { ReportPageComponent } from './pages/report.page/report.page.component';
import { AssessmentsEffects } from './store/effects/assessments.effects';
import { CampaignEffects } from './store/effects/campaign.effects';
import { assessmentsReducer } from './store/reducers/assessments.reducer';
import { campaignReducer } from './store/reducers/campaign.reducer';

export const routes: Routes = [
  {
    path: 'home',
    component: HomePageComponent,
    canActivate: [AuthGuard],
    title: 'Aptem Assess Admin Centre | Dashboard',
    providers: [provideState('campaignSummary', campaignReducer), provideEffects([CampaignEffects])],
  },
  {
    path: 'assessments',
    title: 'Assessments',
    canActivate: [AuthGuard],
    component: AssessmentsPageComponent,
    providers: [
      provideState('assessments', assessmentsReducer),
      provideEffects([AssessmentsEffects]),
      provideState('campaignSummary', campaignReducer),
      provideEffects([CampaignEffects]),
    ],
  },
  {
    path: 'reports',
    canActivate: [AuthGuard, PermissionsGuard],
    data: { permission: Permissions.readBIReports },
    title: 'Aptem Assess Admin Centre | Reports',
    component: ReportPageComponent,
  },
  {
    path: 'campaign-results',
    component: CampaignResultsComponent,
    canActivate: [AuthGuard],
    title: 'Aptem Assess Admin Centre | Campaign Results',
  },
  {
    path: 'login',
    component: LoginPageComponent,
    title: 'Aptem Assess Admin Centre | Log in',
  },
  {
    path: 'logout',
    component: LogoutPageComponent,
    title: 'Aptem Assess Admin Centre | Logged out',
  },
  {
    path: 'error',
    component: ErrorComponent,
    title: 'Aptem Assess Admin Centre | error',
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/error',
  },
];
