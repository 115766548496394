export const environment = {
  production: false,
  auth0: {
    domain: "aptem-assess-staging.uk.auth0.com",
    clientId: "HZ5Eq8kkg8iB0xNNwDjXDIola8kneWea",
    authorizationParams: {
      redirect_uri: "https://admin-staging.aptemassess.co.uk/home",
      audience: "https://uks-aptem-assess-staging-ca02.agreeabledune-8075eda8.uksouth.azurecontainerapps.io",
      scope: "organization email openid profile"
    },
    errorPath: '/callback',
  },
  api: {
    serverUrl: "https://adminapi-staging.aptemassess.co.uk",
    pdfReportUrl: "https://uks-aptem-assess-staging-ca03.niceflower-820c2f53.uksouth.azurecontainerapps.io"
  },
  debounceTime: 30000,
};
