import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PdfReportApiService {
  private baseUrl: string = environment.api.pdfReportUrl;

  constructor(private httpClient: HttpClient) {}

  public getIndividualReportResults(campaignCode: string, assessmentId: number): Observable<Blob> {
    return this.httpClient.get(`${this.baseUrl}/api/v1/campaigns/${campaignCode}/individual-reports/${assessmentId}`, {
      responseType: 'blob',
    });
  }
}
