import { createReducer, on } from '@ngrx/store';
import { setAdminContentByKeyFailure, setAdminContents, setAdminContentSuccess } from '../actions/content.actions';
import { AdminContentState } from '../models/content-models';

export const initialAdminContentState: AdminContentState = {
  content: {
    data: [],
    loading: false,
    error: null,
  },
};

export const adminContentReducer = createReducer(
  initialAdminContentState,
  on(setAdminContents, (state) => ({
    ...state,
    content: {
      data: [],
      loading: true,
      error: null,
    },
  })),

  on(setAdminContentSuccess, (state, { content }) => ({
    ...state,
    content: {
      data: content,
      loading: false,
      error: null,
    },
  })),

  on(setAdminContentByKeyFailure, (state, { error }) => ({
    ...state,
    content: {
      data: [],
      loading: false,
      error,
    },
  })),
);
