import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { AdminPortalApiService } from '../../services/admin-portal-api.service';
import { campaignDataActions, importInviteActions } from '../actions/campaign.actions';

@Injectable()
export class CampaignEffects {
  private actions$ = inject(Actions);

  getCampaignData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(campaignDataActions.getCampaignData),
      exhaustMap(() =>
        this.adminApiService.getDashboards().pipe(
          map((result) => campaignDataActions.getCampaignDataSuccess({ campaignSummaryData: result })),
          catchError((error) => of(campaignDataActions.getCampaignDataFailure({ error: error.message }))),
        ),
      ),
    ),
  );

  sendInvite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(importInviteActions.importInvite),
      exhaustMap(({ campaignCode, externalId, name, email }) =>
        this.adminApiService.importInvitation(campaignCode, externalId, name, email).pipe(
          map(() => importInviteActions.importInviteSuccess()),
          catchError((error) => of(importInviteActions.importInviteDataFailure({ error: error.message }))),
        ),
      ),
    ),
  );

  constructor(private adminApiService: AdminPortalApiService) {}
}
