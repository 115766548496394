import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AptemComponent } from '../common/directives/aptem-component/aptem-component';
import { CalloutConfig } from './callout.models';

@Component({
  selector: 'app-callout',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './callout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalloutComponent extends AptemComponent<CalloutConfig> {
  protected defaultConfig: Required<CalloutConfig> = {
    color: 'default',
    elementId: null,
  };
}
