<div
  [attr.id]="getConfig.elementId || null"
  [attr.data-ua-id]="trackingId"
>
  <div
    *ngIf="value"
    [innerHTML]="getSanitisedContent()"
  ></div>

  <ng-content
    select="[defaultContent]"
    *ngIf="!value"
  />
</div>
