import { ChangeDetectionStrategy, Component } from '@angular/core';
import {PageComponent} from '../../common/page/page.component';
import {AssessmentResultsComponent} from '../../components/assessment-results/assessment-results.component';

@Component({
  selector: 'app-assessments.page',
  standalone: true,
  imports: [
    PageComponent,
    AssessmentResultsComponent
  ],
  templateUrl: './assessments.page.component.html',
  styleUrl: './assessments.page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssessmentsPageComponent {

}
